var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.settingsLoaded)?_c('div',{staticClass:"sm-feed",attrs:{"id":"feed"}},[_c('div',{staticClass:"heading"},[(_vm.projectViewSettings.title !== undefined && _vm.projectViewSettings.title.length > 0)?_c('h1',{staticClass:"title"},[_c('a',{style:({
                color: _vm.projectViewSettings.titleColor,
                fontSize: _vm.projectViewSettings.fontSize,
                fontFamily: _vm.projectViewSettings.fontType
                })},[_vm._v(" "+_vm._s(_vm.projectViewSettings.title)+" ")])]):_vm._e()]),(_vm.projectViewSettings.graphic !== undefined && _vm.projectViewSettings.graphic.length > 0)?_c('img',{staticClass:"banner",attrs:{"src":_vm.projectViewSettings.graphic.startsWith('data:') ? _vm.projectViewSettings.graphic : _vm.imageStorage + _vm.projectViewSettings.graphic,"alt":_vm.projectViewSettings.imageAlt}}):_vm._e(),_c('div',{staticClass:"posts-wrapper"},[_c('masonry',{staticStyle:{"justify-content":"center"},attrs:{"cols":{default: 7, 3000: 6, 2500: 6, 2000: 5, 1500:4, 1000: 3, 768: 2, 320: 1},"gutter":8}},_vm._l((_vm.mentions),function(mention,index){return _c('a',{key:index,staticClass:"post",style:({
                     borderColor: _vm.projectViewSettings.postBoxBorder.color,
                     borderRadius: _vm.projectViewSettings.postBoxBorder.round,
                     borderWidth: _vm.projectViewSettings.postBoxBorder.weight
                 }),attrs:{"href":mention.link,"target":"_blank"}},[(mention.content_picture.length > 0)?_c('img',{staticClass:"post-picture picture-low",class:{'picture-high': mention.cat===1},style:({
                         borderRadius: _vm.projectViewSettings.postBoxBorder.round,
                     }),attrs:{"src":mention.content_picture}}):_vm._e(),_c('div',{staticClass:"post-content",style:({
                        color: _vm.projectViewSettings.postContentText.fontColor,
                        fontFamily: _vm.projectViewSettings.postContentText.fontType,
                        fontSize: _vm.projectViewSettings.postContentText.fontSize
                     })},[_vm._v(" "+_vm._s(mention.content_message)+" "+_vm._s(mention.content_name)+" "+_vm._s(mention.content_description)+" ")]),_c('div',{staticClass:"post-footer"},[(mention.cat === 5)?_c('img',{staticClass:"source",attrs:{"src":require("../assets/feedv3/icon-instagram.png")}}):_vm._e(),(mention.cat === 2)?_c('img',{staticClass:"source",attrs:{"src":require("../assets/feedv3/icon-twitter.png")}}):_vm._e(),(mention.cat === 1)?_c('img',{staticClass:"source",attrs:{"src":require("../assets/feedv3/icon-facebook.png")}}):_vm._e(),_c('a',{staticClass:"link",style:({
                           color: _vm.projectViewSettings.postSeePostButton.fontColor,
                           fontFamily: _vm.projectViewSettings.postSeePostButton.fontType,
                           fontSize: _vm.projectViewSettings.postSeePostButton.fontSize
                       }),attrs:{"href":mention.link,"target":"_blank"}},[_vm._v(" Zobacz "),_c('img',{attrs:{"src":require("../assets/feedv3/chevron-right.png")}})])])])}),0),(_vm.actualPage < _vm.maxPage)?_c('div',{staticClass:"more-posts"},[(_vm.actualPage < _vm.maxPage)?_c('button',{staticClass:"btn",class:{ 'btn-hover': !_vm.checkIfMobile() },style:({
                           color: _vm.projectViewSettings.seeMoreButton.fontColor,
                           fontFamily: _vm.projectViewSettings.seeMoreButton.fontType,
                           fontSize: _vm.projectViewSettings.seeMoreButton.fontSize,
                           backgroundColor: _vm.projectViewSettings.seeMoreButton.backgroundColor,
                           borderColor: _vm.projectViewSettings.seeMoreButton.borderColor,
                           borderRadius: _vm.projectViewSettings.seeMoreButton.borderRound
                       }),attrs:{"disabled":_vm.loader},on:{"click":function($event){return _vm.getData(_vm.actualPage + 1, _vm.limit)}}},[(!_vm.loader)?_c('span',[_vm._v("Pokaż więcej")]):_vm._e(),(_vm.loader)?_c('span',[_vm._v("Ładowanie...")]):_vm._e()]):_vm._e()]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }