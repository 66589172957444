











































































import { Component, Vue } from 'vue-property-decorator';
import VueResource from 'vue-resource'
import Settings from './../services/Settings';
import {Loader, LoaderOptions} from 'google-maps';

Vue.use(VueResource);

@Component({

})
export default class MapComponent extends Vue {
    private uid: string;
    private host = '';
    private imageStorage = '';
    private settingsLoaded = false;
    private projectSettings = {};
    private mapData = {};
    private projectViewSettings = {};
    private categories = [];
    private activeCategory = 0;
    private markers = {};
    private currentZoom = 6;
    private entry = {};
    private defaultImage = '';
    private mapInit = true;
    private defaultMarker = 0;
    private currentPlace = {
        entries: []
    };
    private currentEntry = 0;
    private reloadEntryInterval;
    private reloadEntryTime = 2500;
    private map;
    private google;
    private featuredSectionShow = true;

    created(): void {
        const settings = new Settings();
        this.host = settings.getSetting('apiHost');
        this.imageStorage = settings.getSetting('imageStorage');
        this.uid = this.$route.params.uid;
        this.getProjectSettings();
        this.getData();
    }

    setActiveCategory(categoryId: number) {
        this.activeCategory = categoryId;
        this.currentPlace = {
            entries: []
        };
        this.currentEntry = 0;
        for(const ent in this.mapData) {
            if (this.activeCategory === 0 || this.mapData[ent].category_id === this.activeCategory) {
                this.currentPlace.entries.push(this.mapData[ent].id);
            }
        }
        this.setEntry();
        this.putMarkers(false);
        this.setAutoload();
    }

    setAutoload() {
        clearInterval(this.reloadEntryInterval);
        const that = this;
        this.reloadEntryInterval = setInterval(() => {
            that.setNextEntry();
        }, this.reloadEntryTime)
    }

    clearAutoload() {
        clearInterval(this.reloadEntryInterval);
    }

    setEntry() {
        this.entry = this.mapData[this.currentPlace.entries[this.currentEntry]];
        this.defaultImage = this.getDefaultImage();

    }

    setNextEntry(withAutoload = true) {
        if (this.currentEntry + 1 >= this.currentPlace.entries.length) {
            this.currentEntry = 0;
        } else {
            this.currentEntry += 1;
        }
        this.setEntry();
        if (!withAutoload) {
            this.clearAutoload();
        }
    }

    setPrevEntry(withAutoload = true) {
        if (this.currentEntry > 0) {
            this.currentEntry -= 1;
        }
        this.setEntry();
        if (!withAutoload) {
            this.clearAutoload();
        }
    }


    getProjectSettings() {
        this.$http.get(this.host + '/apis/' + this.uid + '/project', {

        }).then((response: any) => {
            this.projectSettings = response.body;
            this.projectViewSettings = response.body.credentials.map;
            this.defaultMarker = response.body.credentials.map.marker.id;
            this.settingsLoaded = true;
        });
    }

    getData() {
        this.$http.get(this.host + '/api/map/' + this.uid, {

        }).then((response: any) => {
            this.mapData = response.body.data.entries;
            this.categories = response.body.data.categories;
            this.getDefaultImage(3);
            this.loadMap();

            setTimeout(() => {
                this.setActiveCategory(0);
            }, 1000);

        });
    }

    getDefaultImage() {
        let image = '';
        for(let i in this.categories) {
            const category = this.categories[i];
            if (category.id == this.entry.category_id) {
                image = category.image;
                break;
            }
        }

        return image;
    }

    loadMap() {
        const settings = new Settings();
        const mapApiKey = settings.getSetting('mapApiKey');
        const options: LoaderOptions = {/* todo */};
        const loader = new Loader(mapApiKey, options);
        let that = this;

        loader.load().then(function (google) {
            that.google = google;
            const bounds = new google.maps.LatLngBounds(
                {lat: 49.00, lng: 14.11666667},
                {lat: 54.83333333, lng: 24.15}
            );
            const customMapType = new google.maps.StyledMapType([
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#caadfe"
                        },
                        {
                            "weight": "2"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#000000"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "hue": "#0092ff"
                        },
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.province",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "color": "#ca1b1b"
                        },
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "hue": "#ff0000"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f7f7f7"
                        }
                    ]
                },
                {
                    "featureType": "landscape.man_made",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.attraction",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#a0a0a0"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#95d8ff"
                        }
                    ]
                }
            ], {
                name: ''
            });
            const customMapTypeId = 'custom_style';

            that.map = new google.maps.Map(document.getElementById('map'), {
                center: bounds.getCenter(),
                zoom: 6,
                scrollwheel: true,
                //disableDefaultUI: true,
                //disableDoubleClickZoom: true,
                draggable: true,
                minZoom: 4,
                maxZoom: 15,
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, customMapTypeId]
                }
            });
            that.map.mapTypes.set(customMapTypeId, customMapType);
            that.map.setMapTypeId(customMapTypeId);

            that.map.addListener('idle', function() {
                that.putMarkers(false);
            });

            setTimeout(() => {
                that.putMarkers(true);

            }, 1000);


        });
    }

    putMarkers(init) {
        const map = this.map;
        // if (map === undefined) {
        //     return;
        // }
        // if(map.getZoom()==this.currentZoom) return;

        this.currentZoom = map.getZoom();

        const bounds = map.getBounds().toJSON();
        const map_width = document.getElementById('map').offsetWidth; // $('#map').innerWidth();
        const map_height = document.getElementById('map').offsetHeight; // $('#map').innerHeight();
        const lat_resolution = (map.getBounds().toJSON().north-map.getBounds().toJSON().south)/(map_height/70);
        const lng_resolution = (map.getBounds().toJSON().east-map.getBounds().toJSON().west)/(map_width/50);

        // console.log("putMarkers("+init+"): "+map.getZoom()+": "+lat_resolution+", "+lng_resolution);

        for(const id in this.markers) {
            if(this.markers[id].marker) {
                this.markers[id].marker.setMap(null);
            }
        }
        this.markers = {};

        for(const id in this.mapData) {
            const entry = this.mapData[id];
            let place_id = entry.place_id;

            for(const marker_id in this.markers) {
                if(
                    Math.abs(this.markers[marker_id].lat-entry.place_lat)<=lat_resolution
                    && Math.abs(this.markers[marker_id].lng-entry.place_lng)<=lng_resolution
                ) {
                    place_id = marker_id;
                    break;
                }
            }
            if (this.activeCategory === 0 || this.activeCategory === entry.category_id) {
                if(!this.markers.hasOwnProperty(place_id)) {
                    this.markers[place_id] = {
                        id: place_id,
                        lat: entry.place_lat,
                        lng: entry.place_lng,
                        title: entry.place_title,
                        marker: null,
                        count: 0,
                        entries: []
                    };
                }

                this.markers[place_id].count++;
                this.markers[place_id].entries.push(id);
            }

        }

        let icon;
        for(const id in this.markers) {
            if(this.markers[id].count==1) {
                icon = {
                    //url: 'marker.php?v=2&nr='+markers[id].count,
                    //url: '/widget/map/images/marker.png',
                    url: this.host + '/widget/marker/' + this.markers[id].count + '/' + this.mapData[this.markers[id].entries[0]].category_id + '/' + this.defaultMarker,
                    anchor: new this.google.maps.Point(23.5,64)
                }
            } else if(this.markers[id].count<=9) {
                icon = {
                    url: this.host + '/widget/marker/' + this.markers[id].count + '/' + this.mapData[this.markers[id].entries[0]].category_id + '/' + this.defaultMarker,
                    anchor: new this.google.maps.Point(23.5,64)
                }
            } else {
                icon = {
                    url: this.host + '/widget/marker/' + this.markers[id].count + '/' + this.mapData[this.markers[id].entries[0]].category_id + '/' + this.defaultMarker,
                    anchor: new this.google.maps.Point(23.5,64)
                }
            }

            this.markers[id].marker = new google.maps.Marker({
                position: {lat: this.markers[id].lat, lng: this.markers[id].lng },
                map: map,
                icon: icon,
                animation: this.mapInit ? google.maps.Animation.DROP : null,
                title: this.markers[id].title,
                id: id
            });
            const that = this;
            this.markers[id].marker.addListener('click',function() {
                that.markerClick(this.id);
            });
        }
        // console.log(this.markers);
        this.mapInit = false;
    }

    markerClick(id: number) {
        // console.log('clicked marker: ', id);
        this.currentPlace = this.markers[id];
        this.currentEntry = 0;
        this.setEntry();
        this.setAutoload();
    }

    toogleFeaturedSection() {
        if (this.featuredSectionShow){
            const elementInfo = document.getElementById("feature");
            elementInfo.classList.add("width-0");
            const elementMap = document.getElementById("map");
            elementMap.classList.add("width-100");
            const elementIcon = document.getElementById("icon");
            elementIcon.classList.remove("fa-angle-right");
            elementIcon.classList.add("fa-angle-left");
            this.featuredSectionShow = false;
        } else {
            const elementInfo = document.getElementById("feature");
            elementInfo.classList.remove("width-0");
            const elementMap = document.getElementById("map");
            elementMap.classList.remove("width-100");
            const elementIcon = document.getElementById("icon");
            elementIcon.classList.add("fa-angle-right");
            elementIcon.classList.remove("fa-angle-left");
            this.featuredSectionShow = true;
        }
    }

}
