





































import { Component, Vue } from 'vue-property-decorator';
import VueResource from 'vue-resource'
import Settings from './../services/Settings';
import round from 'vue-round-filter';

Vue.use(VueResource);

@Component({
    filters: {
        round
    }
})
export default class NoFeedComponent extends Vue {
    private uid: string = '';
    private host = '';
    private imageStorage = '';
    private settingsLoaded = false;
    private projectSettings = {};
    private projectViewSettings = {};

    private stats = [];

    created(): void {
        const settings = new Settings();
        this.host = settings.getSetting('apiHost');
        this.imageStorage = settings.getSetting('storagePath');
        this.uid = this.$route.params.uid;
        this.getProjectSettings();
        this.getData();
    }

    getProjectSettings() {
        this.$http.get(this.host + '/apis/' + this.uid + '/project', {

        }).then((response: any) => {
            this.projectSettings = response.body;
            this.projectViewSettings = response.body.credentials.no_feed;

            this.settingsLoaded = true;
        });
    }

    getData() {
        this.$http.get(this.host + '/api/nofeed/' + this.uid, {

        }).then((response: any) => {
            this.stats = response.body.stat;
        });
    }
}
