

























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Feedv1Component extends Vue {

}
