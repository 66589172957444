export default class Settings {
  private settings: any = {
    mapApiKey: 'AIzaSyD_WFqA0Z348Rwr-qMy4Dwh3-eSG3zdIyg',
    apiHost: 'https://api.tvn.atpsolutions.ovh',
    storagePath: 'https://api.tvn.atpsolutions.ovh/storage/'
  };
  constructor() {
    const host = window.location.host;
    if (host.startsWith('localhost')) {
      this.settings.apiHost = 'https://api.tvn.atpsolutions.ovh';
      this.settings.storagePath = 'https://api.tvn.atpsolutions.ovh/storage/';
    }
    if (host.startsWith('widget.staging.tvn.hashtags.pl')) {
      this.settings.apiHost = 'https://api.staging.tvn.hashtags.pl';
      this.settings.storagePath = 'https://api.staging.tvn.hashtags.pl/storage/';
    }
    if (host.startsWith('tvn.atpsolutions.ovh')) {
      this.settings.apiHost = 'https://api.tvn.atpsolutions.ovh';
      this.settings.storagePath = 'https://api.tvn.atpsolutions.ovh/storage/';
    }
    if (host.startsWith('widget.hashtags.pl') || host.startsWith('localhost')) {
      this.settings.apiHost = 'https://api.hashtags.pl';
      this.settings.storagePath = 'https://api.hashtags.pl/storage/';
    }
    
  }
  
  getSetting(name: string) {
    return this.settings[name];
  }
  
  setSetting(name: string, value: any) {
    this.settings[name] = value;
  }
}
